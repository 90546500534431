import { Button, Icon, Popover, Text } from '@vli/locomotive-ds';
import { CloseIcon, TrashIcon } from '@vli/locomotive-ds/dist';
import { ReactComponent as DownloadIcon } from 'assets/icons/download.svg';
import { ReactComponent as EditItemIcon } from 'assets/icons/edit-item.svg';
import { ReactComponent as ReportIcon } from 'assets/icons/report.svg';
import { ReactComponent as IcOption } from 'assets/icons/table/options.svg';
import * as S from 'components/core/Table/CellWithActions/CellWithActions.styles';
import {
  canCloseContract,
  canEditContract,
  getContractStatus as contractIsClosed,
  getDieselReportParams,
  hasPermissionToDeleteContract,
  hasPermissionToDownloadContract,
} from 'lib/contexts/contracts';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ContractActions } from 'store/contract/contract.ducks';

const CellActions = {
  Edit: 1,
  DieselReport: 2,
  InflationReport: 3,
  Download: 4,
  Close: 5,
  Delete: 6,
};

const CellWithActions = ({ row }) => {
  const { original } = row;

  const dispatch = useDispatch();
  const { changeEdit } = useSelector(({ contract }) => contract);

  const edit = () => {
    if (!contractIsClosed({ details: original }) && canEditContract()) {
      dispatch(ContractActions.setEditRow({ row: original, editMode: true }));
    }
  };

  const editConfirm = () => {
    if (changeEdit) {
      dispatch(
        ContractActions.setEditModalOpen({
          modalOpen: true,
        }),
      );

      return;
    }

    dispatch(ContractActions.setEditRow({ row: {}, editMode: false }));
  };

  const handleExportContract = (type) => {
    dispatch(ContractActions.exportContract({ row: original, type }));
  };

  const handleOpenDieselReportModal = () => {
    if (original?.isGrainSegment) {
      const startDate = original?.startPeriod;
      const endDate = original?.endPeriod;
      const contractId = original?.id;

      if (startDate && endDate && contractId) {
        const params = getDieselReportParams(startDate, endDate, contractId);

        dispatch(
          ContractActions.exportDieselReport({
            row: {
              ...row.values,
              startDate: new Date(startDate),
              endDate: new Date(endDate),
            },
            type: 0,
            formParams: params,
          }),
        );
      }
    } else {
      dispatch(
        ContractActions.setReportModalOpen({ row: original, modalOpen: true }),
      );
    }
  };

  const handleOpenCloseContractModal = () => {
    dispatch(
      ContractActions.setJustifyModalOpen({
        row: original,
        modalOpen: true,
      }),
    );
  };

  const handleOpenDeleteModal = () => {
    dispatch(
      ContractActions.setDeleteModalOpen({
        row: original,
        modalOpen: true,
      }),
    );
  };

  const handleDownloadSpreadsheet = () => {
    dispatch(
      ContractActions.downloadSpreadsheet({
        contractId: original.id,
        filename: 'contratos.zip',
      }),
    );
  };

  const handleOnClick = (action) => {
    if (!original) return null;

    switch (action) {
      case CellActions.Edit:
        edit();
        return null;
      case CellActions.DieselReport:
        handleOpenDieselReportModal();
        return null;
      case CellActions.InflationReport:
        handleExportContract('inflation');
        return null;
      case CellActions.Download:
        handleDownloadSpreadsheet();
        return null;
      case CellActions.Close:
        handleOpenCloseContractModal();
        return null;
      case CellActions.Delete:
        handleOpenDeleteModal();
        return null;
      default:
        return null;
    }
  };

  const Options = (
    <S.OptionsContainer>
      {canEditContract() && !contractIsClosed({ details: original }) && (
        <S.OptionItem
          data-testid="test-option-edit"
          onClick={() => handleOnClick(CellActions.Edit)}
        >
          <Icon src={EditItemIcon} />
          <Text size="medium" style={{ fontWeight: '400' }}>
            Editar
          </Text>
        </S.OptionItem>
      )}

      <S.OptionItem
        data-testid="test-option-diesel"
        onClick={() => handleOnClick(CellActions.DieselReport)}
      >
        <Icon src={ReportIcon} />
        <Text size="medium" style={{ fontWeight: '400' }}>
          Relatório detalhado do preço - Diesel
        </Text>
      </S.OptionItem>

      {original.showInflationReport && (
        <S.OptionItem
          data-testid="test-option-inflation"
          onClick={() => handleOnClick(CellActions.InflationReport)}
        >
          <Icon src={ReportIcon} />
          <Text size="medium" style={{ fontWeight: '400' }}>
            Relatório detalhado do preço - Inflação
          </Text>
        </S.OptionItem>
      )}

      {hasPermissionToDownloadContract() && (
        <S.OptionItem
          data-testid="test-option-download"
          onClick={() => handleOnClick(CellActions.Download)}
        >
          <Icon src={DownloadIcon} />
          <Text size="medium" style={{ fontWeight: '400' }}>
            Download de planilha
          </Text>
        </S.OptionItem>
      )}

      {canCloseContract({ details: original }) && (
        <S.OptionItem
          data-testid="test-option-close"
          onClick={() => handleOnClick(CellActions.Close)}
        >
          <Icon src={CloseIcon} />
          <Text size="medium" style={{ fontWeight: '400' }}>
            Encerrar formalização
          </Text>
        </S.OptionItem>
      )}

      {hasPermissionToDeleteContract() && original.canDeleteContract && (
        <S.OptionItem
          data-testid="test-option-delete"
          onClick={() => handleOnClick(CellActions.Delete)}
        >
          <Icon src={TrashIcon} />
          <Text size="medium" style={{ fontWeight: '400' }}>
            Deletar
          </Text>
        </S.OptionItem>
      )}
    </S.OptionsContainer>
  );

  return !row.original.editMode ? (
    <S.ContentCell data-testid="cell-with-action">
      <Popover content={Options} position="left center">
        <Button
          data-testid="cell-with-action-option-button"
          icon={IcOption}
          name=""
          variant="tertiary"
        />
      </Popover>
    </S.ContentCell>
  ) : (
    <S.Flex data-testid="cell-with-action-edit">
      <Button
        onClick={editConfirm}
        data-testid="cell-with-edit-confirm-button"
        name="Ok"
        variant="primary"
      />
    </S.Flex>
  );
};

CellWithActions.propTypes = {
  row: PropTypes.shape({
    index: PropTypes.number.isRequired,
    original: PropTypes.shape({
      id: PropTypes.string.isRequired,
      showInflationReport: PropTypes.bool.isRequired,
      endPeriod: PropTypes.string,
      editMode: PropTypes.bool,
      canDeleteContract: PropTypes.bool,
    }).isRequired,
  }),
};

CellWithActions.defaultProps = {
  row: {
    index: 0,
    original: {
      id: 0,
      editMode: false,
      endPeriod: null,
      canDeleteContract: false,
    },
  },
};

export default CellWithActions;
